import i18n from "./i18n";

const submit = require("registrations/submit.enabled")
const zxcvbn = require("zxcvbn")

const password_strengths_labels = [
    'none',
    'very_weak',
    'weak',
    'medium',
    'strong'
];

$(function() {
    var password = $('#user_password');
    var passwordLength = $('.password-hint .length');
    var passwordStrength = $('.password-hint .strength');
    var passwordSpecialCharacters = $('.password-hint .special');
    var confirmPassword = $("#user_password_confirmation");
    var message = $('#password_error_message')
    var feedback = $('#confirm_password_feedback');
    var passwordMeter = $("#pass_meter");

    if(password.length) {
        submit.enableSubmitButton('password', false);
    }

    if(confirmPassword.length) {
        message.hide();
        submit.enableSubmitButton('confirm-password', false);
    }

    // Listen for keyup action on password field
    password
        .on("keyup change blur input paste", function() {
            var value = this.value;
            var a = validateLength(value, passwordLength);
            var b = validateStrength(value, passwordStrength);
            var c = validateSpecialCharacters(value, passwordSpecialCharacters);

            submit.enableSubmitButton('password', a && b && c);
            passwordMeter.trigger("password:score", [value]);
            confirmPassword.trigger('change');
        });

    confirmPassword
        .on('blur', function() {
            //On focus out, explain to the user why there is an X.
            if (this.value === password.val()) {
                message.hide();
            } else {
                message.show();
            }
        })
        .on("keyup change blur input paste", function() {
            var isValid = this.value === password.val()
            submit.enableSubmitButton('confirm-password', isValid);

            feedback.removeClass("icon-ok success icon-remove danger");
            if (isValid) {
                feedback.addClass("icon-ok success");
                message.hide();
            }
            else {
                feedback.addClass("icon-remove danger");
                message.show();
            }
        });

        passwordMeter.on("password:score", function(_event, value) {
            var score = 0;
            var strength = password_strengths_labels[score];
            if (value) {
                score = zxcvbn(value, calculateWeakWords()).score;
                strength = password_strengths_labels[score < 1 ? 1 : score];
            }

            $(this).removeClass('none very_weak weak medium strong').text(i18n.t("password.strength."+strength)).addClass(strength);
        });
});

function calculateWeakWords() {
    var extras = ["lutron"];
    var value = $("#user_email").val()
    if(typeof value !== "undefined")
    {
        extras.push(value);
        extras = extras.concat(value.split(/[^\w]/));
    }

    return extras
}

function validateLength(value, el) {
    var isValid = value.length >= 9;

    toggle_password_icons(isValid, el);

    return isValid;
}

function validateStrength(value, el) {
    var score = zxcvbn(value, calculateWeakWords()).score;
    var minimumScore = parseInt(el.data("minimumScore"));
    var isValid = score >= minimumScore;

    toggle_password_icons(isValid, el);

    return isValid;
}

function validateSpecialCharacters(value, el) {
    var regex = new RegExp(el.data('characters'));
    var isValid = !regex.test(value);

    toggle_password_icons(isValid, el);

    return isValid;
}

function toggle_password_icons(isValid, el) {
    el.removeClass('valid tick cross');
    if (isValid) {
        el.addClass('valid tick');
    } else {
        el.addClass('cross');
    }
}
