/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
//
const images = require.context('../images', true)

const $ = require('jquery');

window.$ = $;

require("@rails/ujs").start()
require('bootstrap/dist/js/bootstrap.bundle');
require("tooltip")
require("registrations/email.verification")
require("registrations/password.strength")
require("registrations/country.selection" )
require("registrations/i18n")

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

import('styles/sign_up')
import('styles/application.css');
